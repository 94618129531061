@import "https://fonts.googleapis.com/css2?family=Life+Savers:wght@400;700;800&display=swap";
.hide-mobile {
  display: none !important;
}

@media (width >= 576px) {
  .hide-mobile {
    display: initial !important;
  }
}

.hide-mobile-flex {
  display: none !important;
}

@media (width >= 576px) {
  .hide-mobile-flex {
    display: flex !important;
  }
}

.show-mobile {
  display: initial !important;
}

@media (width >= 576px) {
  .show-mobile {
    display: none !important;
  }
}

.show-mobile-block {
  display: block !important;
}

@media (width >= 576px) {
  .show-mobile-block {
    display: none !important;
  }
}

@keyframes wave {
  0% {
    box-shadow: 0 0 #fff, 0 0 0 4px #fff3, 0 0 0 8px #fff9, 0 0 0 12px #fff6, 0 0 0 16px #fff3;
  }

  100% {
    box-shadow: 0 0 0 16px #fff0, 0 0 0 12px #fff3, 0 0 0 8px #fff6, 0 0 0 4px #fff9, 0 0 #fff;
  }
}

@keyframes waveDark {
  0% {
    box-shadow: 0 0 #42538b, 0 0 0 4px #42538b33, 0 0 0 8px #42538b99, 0 0 0 12px #42538b66, 0 0 0 16px #42538b33;
  }

  100% {
    box-shadow: 0 0 0 16px #42538b00, 0 0 0 12px #42538b33, 0 0 0 8px #42538b66, 0 0 0 4px #42538b99, 0 0 #42538b;
  }
}

:root {
  --tiki-background: linear-gradient(180deg, #3229ba 0%, #cf71b9 51.04%, #fe5560 100%) no-repeat top left;
  --tiki-text-primary: #42538b;
  --tiki-text-secondary: #666;
  --tiki-text-tertiary: #999;
  --tiki-text-light: #fff;
  --tiki-content-bg: #fff;
  --tiki-button: #42538b;
  --tiki-cta: #7fac46;
}

@keyframes background-fade {
  0% {
    background: #fff;
  }

  100% {
    background: #e5ebff;
  }
}

@keyframes background-fade-two {
  0% {
    background: #e5ebff;
  }

  100% {
    background: #fff;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Life Savers, serif;
  font-weight: 400;
  outline: none !important;
}

body {
  background: var(--tiki-background);
  color: var(--tiki-text-primary);
  background-size: contain;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow: hidden;
}

.palms {
  background: url("palms.ec853ef4.svg") 0 0 / 50% no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media (width >= 768px) {
  .palms {
    background-size: 25%;
  }
}

.islands {
  background: url("ocean.d9fe6351.svg") 0 100% no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media (width >= 768px) {
  .islands {
    background: url("island.0d7515f4.svg") 100% 100% / contain no-repeat;
  }
}

.birds {
  background: url("birds.717154e0.svg") 95% 44px / 155px 72px no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media (width >= 768px) {
  .birds {
    background-position: 75% 44px;
    background-size: 256px 119px;
  }
}

header {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
  display: flex;
  overflow: hidden;
}

header .logo {
  text-indent: -9000px;
  background: url("logo.539ce8ce.svg") center / 262px 187px no-repeat;
  width: 262px;
  height: 187px;
  margin: 0 auto;
}

@media (width >= 768px) {
  header .logo {
    margin-top: 0;
  }
}

header h1 {
  letter-spacing: .7px;
  color: var(--tiki-text-light);
  margin-top: 18px;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
}

.content {
  background: var(--tiki-content-bg);
  border-radius: 28px 28px 0 0;
  width: 100%;
  max-width: 430px;
  padding: 64px 36px 12px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 -4px 12px #0000001c;
}

.content h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
}

.content p {
  text-align: center;
  padding: 0 24px;
  font-size: 16px;
}

.content button {
  background: var(--tiki-button);
  color: var(--tiki-text-light);
  text-align: center;
  border: none;
  border-radius: 14px;
  width: 100%;
  margin-top: 32px;
  padding: 20px 40px;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.content button span {
  font-weight: 800;
}

.content button:hover {
  cursor: pointer;
  background: #212a46;
}

.content button:disabled {
  cursor: default;
  background: #42538b33;
}

.content button:disabled span i {
  vertical-align: middle;
  background: #fff;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-top: -8px;
  margin-right: 24px;
  animation: 1s ease-in infinite wave;
  display: inline-block;
  position: relative;
}

.content .copyright {
  text-align: center;
  color: var(--tiki-text-tertiary);
  margin-top: 24px;
  font-size: 14px;
}

.content .copyright a {
  color: var(--tiki-text-tertiary);
}

.content .copyright a:hover {
  color: var(--tiki-text-primary);
}

.content .toggler {
  z-index: 2;
  background: var(--tiki-content-bg);
  border-radius: 28px;
  width: calc(100% - 48px);
  padding: 24px;
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 -4px 12px #00000054;
}

.content .toggler.bump {
  top: -228px;
}

.content .toggler ul {
  list-style: none;
}

.content .toggler ul li {
  color: var(--tiki-text-primary);
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: none;
}

.content .toggler ul li span {
  font-weight: 800;
}

.content .toggler ul li.hide {
  display: none;
}

.content .toggler ul li i.chevron {
  float: right;
  background: url("chevron-up.03b82f7c.svg") center / 24px no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.content .toggler ul li.selected {
  display: block;
}

.content .toggler ul li.selected.open {
  padding: 16px 0;
}

.content .toggler ul li.selected.open i.chevron {
  transform: scaleY(-1);
}

.content ul.ingredients {
  max-height: 60vh;
  list-style: none;
  overflow: auto;
}

.content ul.ingredients li {
  background-color: #fff;
  border-bottom: 1px dotted #42538b38;
  border-radius: 5px;
  padding: 12px 0 18px 5px;
  font-size: 18px;
  transition: background-color 1s;
}

.content ul.ingredients li:nth-last-child(2) {
  border-bottom: none;
  padding-bottom: 9px;
}

.content ul.ingredients li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.content ul.ingredients li.ingredientAdded {
  animation: 2s forwards background-fade;
}

.content ul.ingredients li.ingredientRemoved {
  animation: 2s forwards background-fade-two;
}

.content ul.ingredients li i {
  float: right;
  background: url("x.27efcf6d.svg") center / 12px no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: inline-block;
}

.content ul.ingredients li i:hover {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.content ul.ingredients li button {
  color: var(--tiki-text-primary);
  background: none;
  border: 1px solid #42538b38;
  margin-top: 0;
  padding: 8px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.content ul.ingredients li button:hover {
  background: #42538b1c;
}

.content .drinkName, .content .drinkTaste, .content .alternativeName, .content .alterRecipe, .content .inventoryItem {
  max-height: 480px;
}

.content .drinkName label, .content .drinkTaste label, .content .alternativeName label, .content .alterRecipe label, .content .inventoryItem label {
  margin-bottom: 6px;
  display: block;
}

.content .drinkName label span, .content .drinkTaste label span, .content .alternativeName label span, .content .alterRecipe label span, .content .inventoryItem label span {
  border: 1px solid #42538b38;
  border-radius: 7px;
  padding: 3px 24px 3px 9px;
  font-weight: 800;
  display: inline-block;
}

.content .drinkName input[type="text"], .content .drinkTaste input[type="text"], .content .alternativeName input[type="text"], .content .alterRecipe input[type="text"], .content .inventoryItem input[type="text"] {
  color: var(--tiki-text-primary);
  border: 1px solid #42538b38;
  border-radius: 14px;
  width: 100%;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
}

.content .drinkName textarea, .content .drinkTaste textarea, .content .alternativeName textarea, .content .alterRecipe textarea, .content .inventoryItem textarea {
  resize: vertical;
  color: var(--tiki-text-primary);
  border: 1px solid #42538b38;
  border-radius: 14px;
  width: 100%;
  min-height: 128px;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
}

.content .drinkName input[type="file"], .content .drinkTaste input[type="file"], .content .alternativeName input[type="file"], .content .alterRecipe input[type="file"], .content .inventoryItem input[type="file"] {
  color: var(--tiki-text-primary);
  border: 1px solid #42538b38;
  border-radius: 14px;
  width: 100%;
  margin-bottom: 6px;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
}

.content .drinkName input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.content .drinkTaste input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.content .alternativeName input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.content .alterRecipe input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.content .inventoryItem input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.content .drinkName input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.content .drinkTaste input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.content .alternativeName input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.content .alterRecipe input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.content .inventoryItem input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.content .drinkTaste {
  padding-top: 24px;
}

.alterRecipe span {
  padding-right: 9px !important;
}

.alterRecipe i {
  cursor: pointer;
  background: url("chevron-up.03b82f7c.svg") 100% 0 / 12px no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  display: inline-block;
  transform: scaleY(-1);
}

.responseOverlay {
  z-index: 3;
  width: 100%;
  max-width: 720px;
  height: 100vh;
  padding-top: 12px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.responseOverlay .responseHeader {
  grid-template-columns: 67px 1fr 32px;
  gap: 12px;
  display: grid;
}

.responseOverlay .responseHeader .logo {
  text-indent: -9000px;
  background: url("logo.539ce8ce.svg") center / 67px 48px no-repeat;
  width: 67px;
  height: 48px;
  margin: 0 auto;
}

.responseOverlay .responseHeader i.x {
  cursor: pointer;
  background: url("x.27efcf6d.svg") center / 24px no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.responseOverlay .responseWrap {
  background: var(--tiki-content-bg);
  border-radius: 28px 28px 0 0;
  flex-flow: column;
  height: 100dvh;
  padding: 24px;
  display: flex;
  position: relative;
}

.responseOverlay .responseWrap h2 {
  text-align: center;
  margin-top: 24px;
  font-size: 28px;
  font-weight: 800;
}

.responseOverlay .responseWrap h3 {
  margin: 12px 0 3px;
  font-size: 16px;
  font-weight: 800;
}

.responseOverlay .responseWrap p {
  color: var(--tiki-text-secondary);
  letter-spacing: .7px;
  font-size: 14px;
}

.responseOverlay .responseWrap p.top {
  margin-top: 12px;
  margin-left: 4px;
}

.responseOverlay .responseWrap .response {
  background: #42538b12;
  border-radius: 14px;
  flex: 1;
  margin-top: 12px;
  padding: 24px;
  overflow: auto;
}

.responseOverlay .responseWrap .response h1 {
  letter-spacing: 1px;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 700;
}

.responseOverlay .responseWrap .response ul, .responseOverlay .responseWrap .response ol {
  margin-bottom: 24px;
  margin-left: 12px;
}

.responseOverlay .responseWrap .response ul li, .responseOverlay .responseWrap .response ol li, .responseOverlay .responseWrap .response p {
  color: var(--tiki-text-secondary);
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 26px;
}

.responseOverlay .responseWrap .response.gettingResponse {
  text-align: center;
  padding-top: 64px;
  font-size: 18px;
  font-weight: 700;
}

.responseOverlay .responseWrap .response.gettingResponse i {
  vertical-align: middle;
  background: #42538b;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 0 auto 24px;
  animation: 1s ease-in infinite waveDark;
  display: block;
  position: relative;
}

.responseOverlay .responseWrap .response.selectionResponse p {
  text-align: left;
  color: var(--tiki-text-secondary);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

.responseOverlay .responseWrap .buttonBar {
  text-align: center;
  background: #42538b38;
  border-radius: 14px;
  margin: 12px 0;
  padding: 6px 12px 3px;
}

.responseOverlay .responseWrap .buttonBar button {
  background: var(--tiki-content-bg);
  color: var(--tiki-text-primary);
  cursor: pointer;
  border: 1px solid #42538b70;
  border-radius: 7px;
  min-width: 128px;
  margin: 0 2px 3px;
  padding: 8px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.responseOverlay .responseWrap .buttonBar button:hover {
  background: var(--tiki-text-primary);
  color: var(--tiki-content-bg);
}

.overlayWrapper {
  z-index: 100;
  background: #121212d9;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.overlayWrapper .overlay {
  background: var(--tiki-content-bg);
  border-radius: 28px;
  width: 410px;
  max-width: 580px;
  margin: 12px;
  padding: 24px;
  position: relative;
}

.overlayWrapper .overlay i.x {
  background: var(--tiki-content-bg) url("x.27efcf6d.svg") no-repeat center center;
  filter: drop-shadow(0 2px 12px #00000040);
  cursor: pointer;
  background-size: 24px;
  border-radius: 48px;
  width: 48px;
  height: 48px;
  display: inline-block;
  position: absolute;
  top: -24px;
  right: 24px;
}

.overlayWrapper .overlay i.x:hover {
  background-color: #e1e1e1;
  border: 2px solid #fff;
}

.overlayWrapper .overlay h1.random {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
}

.overlayWrapper .overlay p.random {
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 6px;
  font-size: 16px;
}

.overlayWrapper .overlay .refresh {
  cursor: pointer;
  background: url("refresh.bf7d183f.svg") center / 24px no-repeat;
  width: 24px;
  height: 24px;
  margin: 24px auto 0;
}

.overlayWrapper .overlay label {
  margin-bottom: 6px;
  display: block;
}

.overlayWrapper .overlay label button {
  background: var(--tiki-button);
  color: var(--tiki-text-light);
  letter-spacing: .5px;
  border: none;
  border-radius: 9000px;
  margin-left: 12px;
  padding: 3px 12px;
  font-size: 12px;
  font-weight: 800;
}

.overlayWrapper .overlay label button:hover {
  cursor: pointer;
  background: var(--tiki-cta);
}

.overlayWrapper .overlay .inputWrap {
  position: relative;
}

.overlayWrapper .overlay .smallprint {
  text-align: center;
  font-size: 14px;
  font-weight: 800;
}

.overlayWrapper .overlay button.standard {
  background: var(--tiki-button);
  color: var(--tiki-text-light);
  text-align: center;
  border: none;
  border-radius: 14px;
  width: 100%;
  margin: 8px 0;
  padding: 20px 40px;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.overlayWrapper .overlay button.standard:hover {
  cursor: pointer;
  background: var(--tiki-cta);
}

.overlayWrapper .overlay button.add {
  z-index: 2;
  background: var(--tiki-button) url("plus.3845fdbd.svg") no-repeat center center;
  background-size: 24px;
  border: none;
  border-radius: 9px;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.overlayWrapper .overlay button.add:hover {
  cursor: pointer;
  background-color: #212a46;
}

.overlayWrapper .overlay input[type="text"], .overlayWrapper .overlay input[type="file"] {
  color: var(--tiki-text-primary);
  border: 1px solid #42538b38;
  border-radius: 14px;
  width: 100%;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
}

.overlayWrapper .overlay input::file-selector-button {
  border: 1px solid var(--tiki-button);
  color: var(--tiki-button);
  background: #eff2ff;
  border-radius: 9000px;
  padding: 6px 24px;
  font-family: Life Savers;
  font-weight: 700;
}

.overlayWrapper .overlay input::file-selector-button:hover {
  cursor: pointer;
  background: var(--tiki-button);
  color: var(--tiki-text-light);
}

.overlayWrapper .overlay .comingSoon {
  letter-spacing: .5px;
  color: var(--tiki-text-secondary);
  margin: 6px 0 18px 6px;
  font-size: 12px;
}

.overlayWrapper .overlay ul {
  margin-top: 12px;
  list-style: none;
}

.overlayWrapper .overlay ul li {
  cursor: pointer;
  border: 1px solid #42538b38;
  border-radius: 9000px;
  margin: 0 3px 6px 0;
  padding: 2px 12px;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
}

.overlayWrapper .overlay ul li:hover {
  background: #42538b1c;
}

.textSelectMenu {
  z-index: 2000;
  background: var(--tiki-content-bg);
  border-radius: 9000px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 6px #00000054;
}

.textSelectMenu ul {
  list-style: none;
}

.textSelectMenu ul li {
  border: 1px solid #42538b38;
  border-radius: 9000px;
  padding: 2px 12px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.textSelectMenu ul li:hover {
  cursor: pointer;
  background: #42538b1c;
}

.toast {
  z-index: 3000;
  color: var(--tiki-content-bg);
  letter-spacing: .5px;
  background: #7fac46;
  border-radius: 9000px;
  padding: 12px 24px;
  font-weight: 700;
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 1px 6px #00000054;
}

/*# sourceMappingURL=index.28bfec08.css.map */
