@import url('https://fonts.googleapis.com/css2?family=Life+Savers:wght@400;700;800&display=swap');

/* responsive breakpoints */
$small-device-breakpoint: 576px;
$medium-device-breakpoint: 768px;
$large-device-breakpoint: 992px;
$xl-device-breakpoint: 1200px;
$two-xl-device-breakpoint: 1400px;


.hide-mobile {
  display: none !important;

  @media (min-width: $small-device-breakpoint) {
    display: initial !important;
  }
}
.hide-mobile-flex {
  display: none !important;

  @media (min-width: $small-device-breakpoint) {
    display: flex !important;
  }
}
.show-mobile {
  display: initial !important;

  @media (min-width: $small-device-breakpoint) {
    display: none !important;
  }
}
.show-mobile-block {
  display: block !important;

  @media (min-width: $small-device-breakpoint) {
    display: none !important;
  }
}

// loader animation
@keyframes wave {
 0% {  box-shadow:
  0 0 0 0px rgba(255, 255,255, 1),
  0 0 0 4px rgba(255, 255,255, 0.2),
  0 0 0 8px rgba(255, 255,255, 0.6),
  0 0 0 12px rgba(255, 255,255, 0.4),
  0 0 0 16px rgba(255, 255,255, 0.2)
  }
  100% {  box-shadow:
    0 0 0 16px rgba(255, 255,255, 0),
    0 0 0 12px rgba(255, 255,255, 0.2),
    0 0 0 8px rgba(255, 255,255, 0.4),
    0 0 0 4px rgba(255, 255,255, 0.6),
    0 0 0 0px rgba(255, 255,255, 1)
  }
}
@keyframes waveDark {
 0% {  box-shadow:
  0 0 0 0px rgba(#42538B, 1),
  0 0 0 4px rgba(#42538B, 0.2),
  0 0 0 8px rgba(#42538B, 0.6),
  0 0 0 12px rgba(#42538B, 0.4),
  0 0 0 16px rgba(#42538B, 0.2)
  }
  100% {  box-shadow:
    0 0 0 16px rgba(#42538B, 0),
    0 0 0 12px rgba(#42538B, 0.2),
    0 0 0 8px rgba(#42538B, 0.4),
    0 0 0 4px rgba(#42538B, 0.6),
    0 0 0 0px rgba(#42538B, 1)
  }
}

:root {
  --tiki-background: linear-gradient(180deg, #3229BA 0%, #CF71B9 51.04%, #FE5560 100%) no-repeat top left;
  --tiki-text-primary: #42538B;
  --tiki-text-secondary: #666666; 
  --tiki-text-tertiary: #999999; 
  --tiki-text-light: #ffffff;
  --tiki-content-bg: #ffffff;
  --tiki-button: #42538B;
  --tiki-cta: #7FAC46;
}

// ingredient fade out
@keyframes background-fade {
    0% {
        background:#ffffff;
    }
    100% {
        background:#E5EBFF;
    }
}
@keyframes background-fade-two {
    0% {
        background:#E5EBFF;
    }
    100% {
        background:#ffffff;
    }
}

* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
  font-family: 'Life Savers', serif;
  font-weight: 400;
}

body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  background: var(--tiki-background);
  background-size: contain;
  color: var(--tiki-text-primary);
  overflow: hidden;
}

.palms {
  width: 100vw;
  height: 100vh;
  background: transparent url('./images/palms.svg') no-repeat top left;
  background-size: 50%;
  overflow: hidden;

  @media (min-width: $medium-device-breakpoint) {
    background-size: 25%;
  }
}
.islands {
  width: 100vw;
  height: 100vh;
  background: transparent url('./images/ocean.svg') no-repeat bottom left;
  overflow: hidden;

  @media (min-width: $medium-device-breakpoint) {
    background: transparent url('./images/island.svg') no-repeat bottom right;
    background-size: contain;
  }
}
.birds {
  width: 100vw;
  height: 100vh;
  background: transparent url('./images/birds.svg') no-repeat 95% 44px;
  background-size: 155px 72px;
  overflow: hidden;

  @media (min-width: $medium-device-breakpoint) {
    background-position: 75% 44px;
    background-size: 256px 119px;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  text-align: center;
  overflow: hidden;

  .logo {
    width: 262px;
    height: 187px;
    margin: 0 auto;
    background: transparent url('./images/logo.svg') no-repeat center center;
    background-size: 262px 187px;
    text-indent: -9000px;

    @media (min-width: $medium-device-breakpoint) {
      margin-top: 0;
    }
  }

  h1 {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.7px;
    color: var(--tiki-text-light);
  }
}

.content {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 430px;
  padding: 64px 36px 12px 36px;
  background: var(--tiki-content-bg);
  border-radius: 28px 28px 0px 0px;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.11);

  h3 {
    font-weight: 800;
    text-align: center;
    font-size: 18px;
  }
  p {
    padding: 0 24px;
    text-align: center;
    font-size: 16px;
  }

  button {
    width: 100%;
    margin-top: 32px;
    padding: 20px 40px;
    background: var(--tiki-button);
    border: none;
    border-radius: 14px;
    color: var(--tiki-text-light);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    span {
      font-weight: 800;
    }

    &:hover {
      background: darken(#42538B, 20%);
      cursor: pointer;
    }

    &:disabled {
      background: rgba(#42538B, 20%);
      cursor: default;

      span i {
        display: inline-block;
        vertical-align: middle;
        margin-top: -8px;
        margin-right: 24px;
        width: 4px;
        height: 4px;
        position: relative;
        border-radius: 50%;
        background: #fff;
        animation: wave 1s ease-in infinite;  
      }
    }
  }

  .copyright {
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    color: var(--tiki-text-tertiary);

    a {
      color: var(--tiki-text-tertiary);

      &:hover {
        color: var(--tiki-text-primary);
      }
    }
  }

  .toggler {
    position: absolute;
    z-index: 2;
    top: -42px;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 48px);
    padding: 24px;
    background: var(--tiki-content-bg);
    border-radius: 28px;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.33);

    &.bump {
      top: -228px;
    }

    ul {
      list-style: none;

      li {
        display: none;
        color: var(--tiki-text-primary);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        cursor: pointer;

        span {
          font-weight: 800;
        }

        &.hide {
          display: none;
        }

        i.chevron {
          float: right;
          display: inline-block;
          width: 24px;
          height: 24px;
          background: transparent url('./images/chevron-up.svg') no-repeat center center;
          background-size: 24px;
        }

        &.selected {
          display: block;
        }
        &.selected.open {
          padding: 16px 0;

          i.chevron {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
          }
        }
      }
    }
  }

  ul.ingredients {
    list-style: none;
    max-height: 60vh;
    overflow:auto;

    li {
      padding: 12px 0 18px 5px;
      border-radius: 5px;
      border-bottom: 1px dotted rgba(#42538B, .22);
      background-color: #fff;
      font-size: 18px;
      transition: background-color 1s;

      &:nth-last-child(2) {
        padding-bottom: 9px;
        border-bottom: none;
      }
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      &.ingredientAdded {
        // background-color: #cc3300;
        animation: background-fade 2s forwards;
      }
      &.ingredientRemoved {
        animation: background-fade-two 2s forwards;
      }

      i {
        float: right;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: transparent url('./images/x.svg') no-repeat center center;
        background-size: 12px;

        &:hover {
          border: 1px solid #ccc;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      button {
        margin-top: 0;
        padding: 8px 20px;
        background: transparent;
        border: 1px solid rgba(#42538B, .22);
        color: var(--tiki-text-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &:hover {
          background: rgba(#42538B, .11);
        }
      }
    }
  }

  .drinkName, .drinkTaste, .alternativeName, .alterRecipe, .inventoryItem {
    max-height: 480px;

    label {
      display: block;
      margin-bottom: 6px;

      span {
        display: inline-block;
        padding: 3px 24px 3px 9px;
        border: 1px solid rgba(#42538B, .22);
        border-radius: 7px;
        font-weight: 800;
      }
    }
    input[type=text] {
      width: 100%;
      padding: 18px;
      border: 1px solid rgba(#42538B, .22);
      border-radius: 14px;
      font-size: 16px;
      font-weight: 700;
      color: var(--tiki-text-primary);
    }
    textarea {
      resize: vertical;
      width: 100%;
      min-height: 128px;
      padding: 18px;
      border: 1px solid rgba(#42538B, .22);
      border-radius: 14px;
      font-size: 16px;
      font-weight: 700;
      color: var(--tiki-text-primary);
    }
    input[type=file] {
      width: 100%;
      margin-bottom: 6px;
      padding: 18px;
      border: 1px solid rgba(#42538B, .22);
      border-radius: 14px;
      font-size: 16px;
      font-weight: 700;
      color: var(--tiki-text-primary);
    }
    input::file-selector-button {
      padding: 6px 24px;
      border-radius: 9000px;
      border: 1px solid var(--tiki-button);
      background: #EFF2FF;
      color: var(--tiki-button);
      font-family: 'Life Savers';
      font-weight: 700;
    }
    input::file-selector-button:hover {
      cursor: pointer;
      background: var(--tiki-button);
      color: var(--tiki-text-light);
    }
  }

  .drinkTaste {
    padding-top: 24px;
  }
}

.alterRecipe {
  span {
    padding-right: 9px !important;
  }
  i {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 3px;
    background: transparent url('./images/chevron-up.svg') no-repeat top right;
    background-size: 12px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    cursor: pointer;
  }
}

.responseOverlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 720px;
  height: 100vh;
  padding-top: 12px;

  .responseHeader {
    display: grid;
    grid-template-columns: 67px 1fr 32px;
    gap: 12px;

    .logo {
      width: 67px;
      height: 48px;
      margin: 0 auto;
      background: transparent url('./images/logo.svg') no-repeat center center;
      background-size: 67px 48px;
      text-indent: -9000px;
    }
    
    i.x {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: transparent url('./images/x.svg') no-repeat center center;
      background-size: 24px;
      cursor: pointer;
    }
  }

  .responseWrap {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 24px;
    border-radius: 28px 28px 0 0;
    background: var(--tiki-content-bg);
    height: 100dvh;

    h2 {
      margin-top: 24px;
      font-size: 28px;
      font-weight: 800;
      text-align: center;
    }
    h3 {
      margin: 12px 0 3px 0;
      font-size: 16px;
      font-weight: 800;
      // text-align: center;
    }
    p {
      color: var(--tiki-text-secondary);
      font-size: 14px;
      letter-spacing: 0.7px;
      // text-align: center;

      &.top {
        margin-top: 12px;
        margin-left: 4px;
      }
    }

    .response {
      flex: 1;
      // max-height: 380px;
      margin-top: 12px;
      padding: 24px;
      border-radius: 14px;
      background: rgba(#42538B, .07);
      overflow: auto;

      h1 {
        margin-bottom: 24px;
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      ul, ol {
        margin-bottom: 24px;
        margin-left: 12px;

        li {
          margin-bottom: 12px;
          font-size: 18px;
          line-height: 26px;
          color: var(--tiki-text-secondary);
        }
      }

      p {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 26px;
        color: var(--tiki-text-secondary);
      }

      &.gettingResponse {
        padding-top: 64px;

        i {
          display: block;
          vertical-align: middle;
          margin: 0 auto 24px auto;
          width: 4px;
          height: 4px;
          position: relative;
          border-radius: 50%;
          background: #42538B;
          animation: waveDark 1s ease-in infinite;  
        }

        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      &.selectionResponse {
        p {
          text-align: left;
          font-size: 18px;
          line-height: 26px;
          color: var(--tiki-text-secondary);
          font-weight: 300;
        }
      }
    }

    .buttonBar {
      margin: 12px 0;
      padding: 6px 12px 3px 12px;
      border-radius: 14px;
      background: rgba(#42538B, .22);
      text-align: center;

      button {
        min-width: 128px;
        margin: 0 2px 3px 2px;
        padding: 8px 20px;
        background: var(--tiki-content-bg);
        border: 1px solid rgba(#42538B, .44);
        border-radius: 7px;
        color: var(--tiki-text-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

        &:hover {
          background: var(--tiki-text-primary);
          color: var(--tiki-content-bg);
        }
      }
    }
  }
}

.overlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100dvh;
  background: rgba(#121212, 0.85);

  .overlay {
    position: relative;
    width: 410px;
    max-width: 580px;
    margin: 12px;
    padding: 24px;
    background: var(--tiki-content-bg);
    border-radius: 28px;

    i.x {
      position: absolute;
      top: -24px;
      right: 24px;
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background: var(--tiki-content-bg) url('./images/x.svg') no-repeat center center;
      background-size: 24px;
      filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25));
      cursor: pointer;

      &:hover {
        background-color: #e1e1e1;
        border: 2px solid white;
      }
    }

    h1.random {
      font-weight: 800;
      text-align: center;
      font-size: 24px;
    }
    p.random {
      margin-bottom: 6px;
      font-size: 16px;
      letter-spacing: 1px;
      text-align: center;
    }
    .refresh {
      width: 24px;
      height: 24px;
      margin: 24px auto 0 auto;
      background: transparent url('./images/refresh.svg') no-repeat center center;
      background-size: 24px;
      cursor: pointer;
    }

    label {
      display: block;
      margin-bottom: 6px;

      button {
        margin-left: 12px;
        padding: 3px 12px;
        border-radius: 9000px;
        border: none;
        background: var(--tiki-button);
        color: var(--tiki-text-light);
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0.5px;

        &:hover {
          cursor: pointer;
          background: var(--tiki-cta);
        }
      }
    }
    .inputWrap {
      position: relative;
    }
    .smallprint {
      font-size: 14px;
      font-weight: 800;
      text-align: center;
    }
    button.standard {
      width: 100%;
      margin: 8px 0;
      padding: 20px 40px;
      background: var(--tiki-button);
      border: none;
      border-radius: 14px;
      color: var(--tiki-text-light);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;

      &:hover {
        cursor: pointer;
        background: var(--tiki-cta);
      }
    }
    button.add {
      position: absolute;
      z-index: 2;
      top: 5px;
      right: 5px;
      width: 48px;
      height: 48px;
      border-radius: 9px;
      border: none;
      background: var(--tiki-button) url('./images/plus.svg') no-repeat center center;
      background-size: 24px;

      &:hover {
        background-color: darken(#42538B, 20%);
        cursor: pointer;
      }
    }
    input[type=text], input[type=file] {
      width: 100%;
      padding: 18px;
      border: 1px solid rgba(#42538B, .22);
      border-radius: 14px;
      font-size: 16px;
      font-weight: 700;
      color: var(--tiki-text-primary);

      // ::file-selector-button {
      //   background: var(--tiki-cta);
      //   // border: 1px solid var(--tiki-button);
      //   color: var(--tiki-button);
      // }
    }

    input::file-selector-button {
      padding: 6px 24px;
      border-radius: 9000px;
      border: 1px solid var(--tiki-button);
      background: #EFF2FF;
      color: var(--tiki-button);
      font-family: 'Life Savers';
      font-weight: 700;
    }
    input::file-selector-button:hover {
      cursor: pointer;
      background: var(--tiki-button);
      color: var(--tiki-text-light);
    }

    .comingSoon {
      margin: 6px 0 18px 6px;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: var(--tiki-text-secondary);
    }

    ul {
      list-style: none;
      margin-top: 12px;

      li {
        display: inline-block;
        margin: 0 3px 6px 0;
        padding: 2px 12px;
        border-radius: 9000px;
        border: 1px solid rgba(#42538B, 0.22);
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;

        &:hover {
          background: rgba(#42538B, 0.11);
        }
      }
    }
  }
}

.textSelectMenu {
  position: absolute;
  z-index: 2000;
  padding: 6px;
  border-radius: 9000px;
  background: var(--tiki-content-bg);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.33);

  ul {
    list-style: none;

    li {
      display: inline-block;
      padding: 2px 12px;
      border-radius: 9000px;
      border: 1px solid rgba(#42538B, 0.22);
      font-size: 12px;
      font-weight: 700;

      &:hover {
        background: rgba(#42538B, 0.11);
        cursor: pointer;
      }
    }
  }
}

.toast {
  position: fixed;
  z-index: 3000;
  top: 12px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 12px 24px;
  border-radius: 9000px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.33);
  background: #7FAC46;
  color: var(--tiki-content-bg);
  font-weight: 700;
  letter-spacing: 0.5px;
}